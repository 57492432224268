<template>
  <div class="quiz">
    <h1>Daily Country Song Quiz</h1>
    <h2>A new question daily!</h2>
    <div v-if="currentQuestion < questions.length" class="question-container">
      <p class="clue"><strong>Clue 1:</strong> {{ questions[currentQuestion].clue1 }}</p>
      <p class="clue"><strong>Clue 2:</strong> {{ questions[currentQuestion].clue2 }}</p>
      <p class="clue"><strong>Clue 3:</strong> {{ questions[currentQuestion].clue3 }}</p>
      <input v-model="userAnswer" class="answer-input" placeholder="Guess the song" />
      <button @click="checkAnswer" class="submit-button">Submit</button>
      <p v-if="feedback" class="feedback">{{ feedback }}</p>
    </div>
    <div v-else class="completion-container">
      <h2>Congratulations, you completed the quiz!</h2>
      <button @click="resetQuiz" class="reset-button">Play Again</button>
      <div class="share-buttons">
        <h3>Share Your Score</h3>
        <button @click="shareScore" class="share-button">Share</button>
        <a :href="facebookShareUrl" target="_blank" class="share-button facebook">Share on Facebook</a>
        <a :href="twitterShareUrl" target="_blank" class="share-button twitter">Share on Twitter</a>
      </div>
    </div>
    <div class="stats">
      <h2>Your Stats</h2>
      <p>Total Correct Answers: {{ totalCorrect }}</p>
      <p>Total Incorrect Answers: {{ totalIncorrect }}</p>
      <p>Percentage Correct: {{ percentageCorrect }}%</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        { clue1: "Released in 1971", clue2: "Album is: With Love", clue3: "Rain", answer: "Many blooms still linger there"},
        // { clue1: "Released in 2005", clue2: "Artist: Carrie Underwood", clue3: "A song about revenge on a cheating partner", answer: "Before He Cheats" }
      ],
      currentQuestion: 0,
      userAnswer: "",
      feedback: "",
      totalCorrect: 0,
      totalIncorrect: 0
    };
  },
  computed: {
    percentageCorrect() {
      const total = this.totalCorrect + this.totalIncorrect;
      return total === 0 ? 0 : ((this.totalCorrect / total) * 100).toFixed(2);
    },
    facebookShareUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareUrl)}`;
    },
    twitterShareUrl() {
      return `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.shareText)}&url=${encodeURIComponent(this.shareUrl)}`;
    },
    shareText() {
      return `My lifetime score is ${this.percentageCorrect}% on the Country Song Quiz! Can you beat my score?`;
    },
    shareUrl() {
      return window.location.href;
    }
  },
  methods: {
    checkAnswer() {
      if (this.userAnswer.toLowerCase() === this.questions[this.currentQuestion].answer.toLowerCase()) {
        this.feedback = "Congrats, you got it right!";
        this.incrementCorrect();
        setTimeout(() => {
          this.currentQuestion++;
          this.userAnswer = "";
          this.feedback = "";
        }, 2000);
      } else {
        this.feedback = "Sorry, that's wrong. Try again.";
        this.incrementIncorrect();
      }
    },
    resetQuiz() {
      this.currentQuestion = 0;
      this.userAnswer = "";
      this.feedback = "";
    },
    incrementCorrect() {
      this.totalCorrect++;
      localStorage.setItem('totalCorrect', this.totalCorrect);
    },
    incrementIncorrect() {
      this.totalIncorrect++;
      localStorage.setItem('totalIncorrect', this.totalIncorrect);
    },
    loadStats() {
      const correct = localStorage.getItem('totalCorrect');
      const incorrect = localStorage.getItem('totalIncorrect');
      this.totalCorrect = correct ? parseInt(correct) : 0;
      this.totalIncorrect = incorrect ? parseInt(incorrect) : 0;
    },
    shareScore() {
      if (navigator.share) {
        navigator.share({
          title: 'Country Song Quiz',
          text: this.shareText,
          url: this.shareUrl
        }).then(() => {
          console.log('Thanks for sharing!');
        }).catch(console.error);
      } else {
        alert('Web Share API is not supported in your browser. Please use the buttons below to share.');
      }
    }
  },
  mounted() {
    this.loadStats();
  }
};
</script>

<style>
.quiz {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
}

h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.question-container, .completion-container {
  margin-bottom: 20px;
}

.clue {
  font-size: 1.2em;
  margin: 10px 0;
}

.answer-input {
  width: calc(100% - 22px);
  padding: 10px;
  font-size: 1em;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submit-button, .reset-button, .share-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
  text-decoration: none;
  text-align: center;
}

.submit-button:hover, .reset-button:hover, .share-button:hover {
  background-color: #0056b3;
}

.share-buttons {
  margin-top: 20px;
}

.share-button {
  margin-right: 10px;
}

.feedback {
  font-size: 1.2em;
  margin-top: 10px;
  color: #28a745;
}

.stats {
  margin-top: 30px;
  text-align: left;
}

.stats h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.stats p {
  font-size: 1.2em;
  margin: 5px 0;
}
</style>

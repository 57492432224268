<template>
  <div id="app">
    <Quiz />
  </div>
</template>

<script>
import Quiz from './components/QuizComponent.vue';

export default {
  name: 'App',
  components: {
    Quiz
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: auto
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 30px;
  padding-bottom: 20px;
}

p {
  font-size: 20px;
  font-weight: 400;
}

.quiz-input {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 20px;
  background-color: #4b5358;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input {
  padding: 10px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px;
}
</style>

